var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"layout_checkout"}},[_c('div',{attrs:{"id":"header_container_checkout"}},[_c('div',{attrs:{"id":"header"}},[_c('div',{staticClass:"back-arrow",on:{"click":function($event){return _vm.$router.back()}}},[(
            !_vm.route.fullPath.includes('thank-you') ||
              !_vm.route.fullPath.includes('error-page')
          )?_c('svg',{attrs:{"width":"41","height":"20","viewBox":"0 0 41 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.46967 9.46967C9.17678 9.76256 9.17678 10.2374 9.46967 10.5303L14.2426 15.3033C14.5355 15.5962 15.0104 15.5962 15.3033 15.3033C15.5962 15.0104 15.5962 14.5355 15.3033 14.2426L11.0607 10L15.3033 5.75736C15.5962 5.46447 15.5962 4.98959 15.3033 4.6967C15.0104 4.40381 14.5355 4.40381 14.2426 4.6967L9.46967 9.46967ZM31 10.75C31.4142 10.75 31.75 10.4142 31.75 10C31.75 9.58579 31.4142 9.25 31 9.25L31 10.75ZM10 10.75L31 10.75L31 9.25L10 9.25L10 10.75Z","fill":"#222A24"}})]):_vm._e()]),_vm._v(" "),_c('nuxt-link',{staticClass:"sf-header__logo",attrs:{"to":_vm.localePath('/')}},[_c('img',{staticClass:"sf-header__logo-image",attrs:{"src":require('../assets/images/header/Logo_Sito.png'),"alt":"Logo","height":"26","width":"187"}})]),_vm._v(" "),(
          !_vm.route.fullPath.includes('thank-you') ||
            !_vm.route.fullPath.includes('error-page')
        )?_c('div',{staticClass:"hidden-logo"},[_vm._v("\n        hide\n      ")]):_c('div')],1)]),_vm._v(" "),_c('div',{staticClass:"layout__custom-checkout",class:{ 'header--sticky': _vm.checkSticky }},[_c('nuxt',{key:_vm.route.fullPath})],1),_vm._v(" "),_c('CartSidebar'),_vm._v(" "),_c('LoginModal'),_vm._v(" "),_c('Notification'),_vm._v(" "),(
      !_vm.route.fullPath.includes('thank-you') &&
        !_vm.route.fullPath.includes('error-page')
    )?_c('CheckoutFooter'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }