<template>
  <component :is="componentErrorLayoutToLoad" />
</template>
<script>
import { useRouter, ref } from '@nuxtjs/composition-api';
// import { SfButton, SfImage, SfHeading } from '@storefront-ui/vue';
// import { addBasePath } from '@vue-storefront/core';
import Error404 from '~/pages/Errors/ErrorCode404.vue';
import Error500 from '~/pages/Errors/ErrorCode500.vue';
import Error503 from '~/pages/Errors/ErrorCode503.vue';
import ErrorDefault from '~/pages/Errors/DefaultErrorCode.vue';

export default {
  name: 'ErrorLayout',

  components: {
    // SfButton,
    // SfImage,
    // SfHeading
  },

  props: {
    error: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const errorCode = ref(props.error);
    return {
      router,
      errorCode,
    };
  },

  data() {
    return {
      errorsMap: {
        404: Error404,
        500: Error500,
        503: Error503,
        default: ErrorDefault,
      },
    };
  },

  computed: {
    componentErrorLayoutToLoad() {
      return this.errorsMap[this.errorCode.statusCode] ? this.errorsMap[this.errorCode.statusCode] : this.errorsMap.default;
    },
  },
};
</script>
<style lang="scss">

#error {
  margin: 0 auto;
  margin-top: 50px;
  padding: 0 84px 135px 84px;
  max-width: 1366px;
  @media screen and (max-width: 768px) {
    padding: 0 24px 50px 24px;

  }

  h1 {
    font-size: var(--h1-font-size);
  }

  h4, h4 span, strong {
    font-size: var(--h4-font-size);
  }

  p {
    line-break: anywhere;
  }

  .body-404, .title-404 {
    h3 {
      text-transform: uppercase;
    }
  }
}

.page-404 {
  text-align: center;

  .button-404 {
    width: 100%;

    p {
      display: inline-block;
      background: var(--c-secondary);
      padding: 20px 22px;
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 13px;
      width: 250px;

      &:first-of-type {
        margin-right: 50px;
        @include for-mobile {
          margin: 0 auto;
        }
      }

      &:last-of-type {
        margin-left: 50px;
        @include for-mobile {
          margin: 0 auto;
          margin-top: 25px;
        }
      }

      a {
        color: #fff;
        font-weight: 700;
        font-family: "Spartan";
        text-transform: uppercase;
      }
    }
  }
}

</style>
