import { computed, useRoute } from '@nuxtjs/composition-api';
// @ts-ignore
import { productGetters, useProduct } from '@vue-storefront/gemini';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ConfigurableProductOptions, SwatchData } from '@vue-storefront/gemini-api';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Product } from '@vue-storefront/gemini-api/lib/types/GraphQL';

export const productData = () => {
  const route = useRoute();
  const { params: { path: productSlug }, query } = route.value;
  const cacheId = `product-${productSlug}`;
  const { products, loading } = useProduct(cacheId);

  return {
    cacheId,
    loading,
    product: computed(() => {
      const baseProduct = Array.isArray(products?.value?.items) && products?.value?.items[0] ? products?.value?.items[0] : [];
      // @ts-ignore
      return productGetters.getFiltered(baseProduct, {
        master: true,
        attributes: query,
      });
    }),
  };
};

export const getSfColor = (swatchData: SwatchData[] | SwatchData) => {
  let sd: SwatchData;

  if (Array.isArray(swatchData)) {
    if (swatchData.length === 0) {
      return '';
    }
    [sd] = swatchData;
  } else {
    sd = swatchData;
  }

  const hc = productGetters.getSwatchDataHexCode(sd);
  const t = productGetters.getSwatchDataThumbnail(sd);
  if (t === undefined) {
    return hc;
  }
  return `url("${t}") center center / contain no-repeat`;
};

export const getSfColorsFromAttributes = (attribute: ConfigurableProductOptions) => attribute?.values?.map((o) => ({
  value: o.uid,
  color: getSfColor(o.swatch_data),
  label: o.label,
}));

// eslint-disable-next-line consistent-return
export const getProductColorAttribute = (product: Product) => {
  if (product?.configurable_options) {
    return product?.configurable_options
      .find((option) => option.attribute_code === 'colore'
        || option.attribute_code === 'attribute1');
  }
};

export const getPriceFixedDecimals = (price: number) => (Math.round(price * 100) / 100).toFixed(2);
