<template>
  <div>
    <LazyHydrate when-visible>
      <TopBar
        class="both"
        :check-sticky="checkSticky"
      />
    </LazyHydrate>
    <div id="header_container">
      <div id="header">
        <AppHeader :check-sticky="checkSticky" />
      </div>
    </div>
    <div id="layout">
      <div
        class="layout__custom"
        :class="{ 'header--sticky': checkSticky }"
      >
        <nuxt :key="route.fullPath" />

        <CartSidebar />
        <LoginModal />
        <WishlistSidebar />
        <Notification />
      </div>
    </div>
    <LazyHydrate when-visible>
      <AppFooter />
    </LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  ref,
  useFetch,
  onMounted,
} from '@nuxtjs/composition-api';
import { useCart, useUser } from '@vue-storefront/gemini';
import AppHeader from '~/components/AppHeader.vue';
import AppFooter from '~/components/AppFooter.vue';
import TopBar from '~/components/TopBar.vue';
import CartSidebar from '~/components/CartSidebar.vue';
import LoginModal from '~/components/LoginModal.vue';
import { useGeminiConfiguration } from '~/composables/useGeminiConfiguration';
import useWishlist from '~/composables/useWishlist';

import Notification from '~/components/Notification';
import WishlistSidebar from '~/components/WishlistSidebar';
import { useI18n } from '../helpers/hooks/usei18n';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    WishlistSidebar,
    LazyHydrate,
    TopBar,
    AppHeader,
    AppFooter,
    CartSidebar,
    LoginModal,
    Notification,
  },

  setup() {
    const route = useRoute();
    const { isAuthenticated, user, load: loadUser } = useUser();
    const { load: loadCart } = useCart();
    const flag = ref(false);
    const {
      loadWishlist,
    } = useWishlist();
    const { locale } = useI18n();
    const { loadConfiguration, cleanCookie } = useGeminiConfiguration();

    onMounted(() => {
      loadUser();
      loadCart();
      loadWishlist();
    });

    useFetch(async () => {
      await loadConfiguration();
      await cleanCookie();
    });

    return {
      route,
      isAuthenticated,
      flag,
      user,
      locale,
    };
  },
  data() {
    const checkSticky = false;

    return {
      checkSticky,
    };
  },
  mounted() {
    this.scroll();
    this.reloadPage();
  },

  methods: {
    scroll() {
      window.addEventListener('scroll', () => {
        const st = window.scrollY;
        this.checkSticky = st >= Number.parseInt('50', 10);
      });
    },
    reloadPage() {
      window.addEventListener(
        'CookiebotOnAccept',
        () => {
          if (window.CookiebotDialog) window.location.reload();
        },
        false,
      );
      window.addEventListener(
        'CookiebotOnDecline',
        () => {
          if (window.CookiebotDialog) window.location.reload();
        },
        false,
      );
    },
  },
});
</script>

<style lang="scss">
@import '~@storefront-ui/vue/styles';
#header_container {
  position: relative;
  z-index: 99;
  top: 0;
}
#header {
  z-index: 9;
  width: 100%;
}
@font-face {
  font-family: 'Spartan';
  src: url('~@/assets/fonts/spartan/Spartan-Black.ttf');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('~@/assets/fonts/spartan/Spartan-Bold.ttf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('~@/assets/fonts/spartan/Spartan-ExtraBold.ttf');
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('~@/assets/fonts/spartan/Spartan-ExtraLight.ttf');
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('~@/assets/fonts/spartan/Spartan-Light.ttf');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('~@/assets/fonts/spartan/Spartan-Medium.ttf');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('~@/assets/fonts/spartan/Spartan-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('~@/assets/fonts/spartan/Spartan-SemiBold.ttf');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('~@/assets/fonts/spartan/Spartan-Thin.ttf');
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('~@/assets/fonts/spartan/Spartan-VariableFont_wght.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('~@/assets/fonts/frank_ruhl_libre/FrankRuhlLibre-Black.ttf');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('~@/assets/fonts/frank_ruhl_libre/FrankRuhlLibre-Bold.ttf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('~@/assets/fonts/frank_ruhl_libre/FrankRuhlLibre-Light.ttf');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('~@/assets/fonts/frank_ruhl_libre/FrankRuhlLibre-Medium.ttf');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('~@/assets/fonts/frank_ruhl_libre/FrankRuhlLibre-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

#layout {
  box-sizing: border-box;
  @include for-desktop {
    max-width: 100% !important;
    margin: auto;
  }
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

// Reset CSS
html {
  width: auto;
  overflow-x: hidden;
  @include for-mobile {
    overflow-x: hidden;
  }
}

body {
  overflow-x: hidden;
  color: var(--c-text);
  font-size: var(--font-size--base);
  font-family: 'Frank Ruhl Libre';
  margin: 0;
  padding: 0;

  a {
    text-decoration: none;
    color: #222a24;
    font-family: 'Spartan';
    font-weight: 500;
    &:hover {
      color: var(--c-link-hover);
    }
  }

  h1 {
    font-family: 'Frank Ruhl Libre';
    font-size: var(--h1-font-size);
    line-height: 1.6;
    margin: 0;
  }

  h2 {
    font-family: 'Frank Ruhl Libre';
    font-size: var(--h2-font-size);
    line-height: 1.6;
    font-weight: 400;
    margin: 0;
    text-align: left;
  }

  h3 {
    font-family: 'Frank Ruhl Libre';
    font-size: var(--h3-font-size);
    line-height: 1.6;
    margin: 0;
  }

  h4 {
    font-family: 'Frank Ruhl Libre';
    font-size: var(--h4-font-size);
    line-height: 1.6;
    margin: 0;
  }

  span {
    font-size: 14px;
    line-height: 26px;
    color: #222a24;
    font-family: 'Spartan';
    font-weight: 500;
  }
}

.layout__custom {
  padding-top: 88px;
}

@media (max-width: 1366px) {
  .layout__custom {
    padding-top: 93px;
  }
}
@media (max-width: 768px) {
  .layout__custom {
    padding-top: 60px;
  }
}

.sf-select__error-message {
  span {
    color: inherit;
    font: inherit;
  }
}
</style>
