import jwt_decode from 'jwt-decode';

export const hasAuthorizationError = (customerCookie: string): boolean => {
  if (customerCookie) {
    try {
      let exp = 0;
      const decoded = jwt_decode(customerCookie);
      // @ts-ignore
      if (decoded && decoded?.exp) {
        // @ts-ignore
        exp = decoded.exp * 1000;
      }

      if (Date.now() > exp) {
        return true;
      }
    } catch {
      return true;
    }
  }

  return false;
};
