<template>
  <!-- eslint-disable vue/max-attributes-per-line max-len -->
  <div id="layout_checkout">
    <div id="header_container_checkout">
      <div id="header">
        <div class="back-arrow" @click="$router.back()">
          <svg
            v-if="
              !route.fullPath.includes('thank-you') ||
                !route.fullPath.includes('error-page')
            "
            width="41"
            height="20"
            viewBox="0 0 41 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.46967 9.46967C9.17678 9.76256 9.17678 10.2374 9.46967 10.5303L14.2426 15.3033C14.5355 15.5962 15.0104 15.5962 15.3033 15.3033C15.5962 15.0104 15.5962 14.5355 15.3033 14.2426L11.0607 10L15.3033 5.75736C15.5962 5.46447 15.5962 4.98959 15.3033 4.6967C15.0104 4.40381 14.5355 4.40381 14.2426 4.6967L9.46967 9.46967ZM31 10.75C31.4142 10.75 31.75 10.4142 31.75 10C31.75 9.58579 31.4142 9.25 31 9.25L31 10.75ZM10 10.75L31 10.75L31 9.25L10 9.25L10 10.75Z"
              fill="#222A24"
            />
          </svg>
        </div>
        <nuxt-link :to="localePath('/')" class="sf-header__logo">
          <img
            :src="require('../assets/images/header/Logo_Sito.png')"
            class="sf-header__logo-image"
            alt="Logo"
            height="26"
            width="187"
          >
        </nuxt-link>
        <div
          v-if="
            !route.fullPath.includes('thank-you') ||
              !route.fullPath.includes('error-page')
          "
          class="hidden-logo"
        >
          hide
        </div>
        <div v-else />
      </div>
    </div>
    <div
      class="layout__custom-checkout"
      :class="{ 'header--sticky': checkSticky }"
    >
      <nuxt :key="route.fullPath" />
    </div>
    <CartSidebar />
    <LoginModal />
    <Notification />
    <CheckoutFooter
      v-if="
        !route.fullPath.includes('thank-you') &&
          !route.fullPath.includes('error-page')
      "
    />
  </div>
</template>

<script>
import {
  useRoute,
  defineComponent,
  ref,
  useFetch,
  onMounted,
} from '@nuxtjs/composition-api';
import { useCart, useUser } from '@vue-storefront/gemini';
import CartSidebar from '~/components/CartSidebar.vue';
import LoginModal from '~/components/LoginModal.vue';
import { useGeminiConfiguration } from '~/composables/useGeminiConfiguration';

import CheckoutFooter from '~/components/CheckoutFooter.vue';
import Notification from '~/components/Notification';
import { useI18n } from '../helpers/hooks/usei18n';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    CartSidebar,
    LoginModal,
    Notification,
    CheckoutFooter,
  },

  setup() {
    const route = useRoute();
    const { isAuthenticated, user, load: loadUser } = useUser();
    const { load: loadCart } = useCart();
    const flag = ref(false);
    const checkSticky = ref(false);

    const { locale } = useI18n();
    const { loadConfiguration, cleanCookie } = useGeminiConfiguration();

    onMounted(() => {
      loadUser();
      loadCart();
    });

    useFetch(async () => {
      await loadConfiguration();
      await cleanCookie();
    });

    const scroll = () => {
      window.addEventListener('scroll', () => {
        const st = window.scrollY;
        checkSticky.value = st >= Number.parseInt('50', 10);
      });
    };

    const reloadPage = () => {
      window.addEventListener(
        'CookiebotOnAccept',
        () => {
          if (window.CookiebotDialog) window.location.reload();
        },
        false,
      );
      window.addEventListener(
        'CookiebotOnDecline',
        () => {
          if (window.CookiebotDialog) window.location.reload();
        },
        false,
      );
    };

    onMounted(() => {
      scroll();
      reloadPage();
    });

    return {
      route,
      isAuthenticated,
      flag,
      user,
      locale,
      checkSticky,
      scroll,
      reloadPage,
    };
  },
});
</script>

<style lang="scss">
html {
  &:has(#layout_checkout) {
    height: 100%;
    body,
    #__nuxt,
    #__layout {
      height: 100%;
    }
  }
}
#layout_checkout {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include for-desktop {
    max-width: 100% !important;
    margin: auto;
    background-color: var(--_c-pink-primary);
  }
  #header_container_checkout {
    position: relative;
    z-index: 99;
    top: 0;
    padding: 20px 0;
    background-color: white;
    #header {
      z-index: 9;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      max-width: 1414px;
      .hidden-logo {
        visibility: hidden;
      }
      @include for-desktop {
        margin-top: 0;
        padding: 0 84px;
        .back-arrow {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .layout__custom-checkout {
    flex-grow: 1;
  }
}
</style>
